












































































































































































































import {MixinScreenSize} from '../../mixins/MixinScreenSize'
import MyWalletSwapFormStep from './MyWalletSwapFormStep.vue'
import SwapInsufficientGasStep from '../../swapToken/SwapInsufficientGasStep.vue'
import SwapLoadingTransactionStep from '../../swapToken/SwapLoadingTransactionStep.vue'
import SwapReviewStep from '../../swapToken/SwapReviewStep.vue'
import SwapSwappedStep from '../../swapToken/SwapSwappedStep.vue'
import SwapUnexpectedErrorStep from '../../swapToken/SwapUnexpectedErrorStep.vue'
import {SwapStep} from '@/enums/SwapStep'
import BigNumber from 'bignumber.js'
import {Component, Prop, Watch} from 'vue-property-decorator'
import SwapDeadlineExceededStep from '../../swapToken/SwapDeadlineExceededStep.vue'
import {NeoHelper} from '@/helpers/NeoHelper'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import {
  BSNeo3NetworkId,
  FlamingoSwapServiceNeo3,
  BSNeo3Constants,
} from '@cityofzion/bs-neo3'
import {Request} from '@simpli/serialized-request'
import {bsNeo3} from '@/libs/bsNeo3'
import {InvokeParams} from '@/model/wallets/types/WalletTypes'
import {EnvHelper} from '@/helpers/EnvHelper'
import {
  BalanceResponse,
  SwapRoute,
  Token,
} from '@/libs/blockchain-services/types'
import {
  SwapServiceSwapToReceiveArgs,
  SwapServiceSwapToUseArgs,
} from '@/model/wallets/invocationsBuilder/GenericInvocationBuilder'

@Component({
  components: {
    SwapDeadlineExceededStep,
    SwapUnexpectedErrorStep,
    SwapInsufficientGasStep,
    SwapSwappedStep,
    SwapLoadingTransactionStep,
    SwapReviewStep,
    MyWalletSwapFormStep,
  },
  computed: {
    SwapStep() {
      return SwapStep
    },
  },
})
export default class MyWalletSwapModal extends MixinScreenSize {
  @Prop({type: Array, required: true}) swappableTokensSymbol!: string[]
  @Prop({type: TokensTableItemsCollection, required: true})
  tokensTableItemsCollection!: TokensTableItemsCollection

  currentStep: SwapStep = SwapStep.FORM

  accountBalance: BalanceResponse[] = []

  tokenToReceive: TokensTableItem | null = null
  tokenToUse: TokensTableItem | null = null

  zeroBn = new BigNumber(0)
  zeroStr = this.zeroBn.toString()

  amountToReceive = this.zeroStr
  amountToUse = this.zeroStr

  maxAmountToUse = this.zeroStr

  maximumSelling: string = this.zeroStr
  minimumReceived: string = this.zeroStr

  reservesToReceive: string = this.zeroStr
  reservesToUse: string = this.zeroStr

  allowedSlippageInPercentage = 0.5
  deadlineInMinutes = 10

  priceInverse = this.zeroStr
  priceImpact = this.zeroStr
  liquidityProviderFee = this.zeroStr

  amountToReceiveInDollar = this.zeroStr
  amountToUseInDollar = this.zeroStr

  lastAmountChanged: 'amountToReceive' | 'amountToUse' | null = null

  invokeParams: InvokeParams | null = null
  transactionHash: string | null = null

  isTokenToUseIndivisible: boolean = false
  isReceiveInputValid: boolean | null = null
  isUseInputValid: boolean | null = null
  isUseMaxValid: boolean | null = null

  isTokenToReceiveIndivisible: boolean = false
  isReceiveSelectValid: boolean | null = null

  wrappingNeo: boolean = false
  unwrappingNeo: boolean = false

  priceImpactIsExtremellyHigh: boolean | null = null
  priceImpactIsHigh: boolean | null = null
  slippageIsHigh: boolean | null = null

  route: TokensTableItem[] = []

  flamingoSwapService = new FlamingoSwapServiceNeo3(
    BSNeo3Constants.DEFAULT_NETWORK,
    bsNeo3
  )

  get canBeClosed() {
    return (
      this.currentStep !== SwapStep.WAITING_SIGNATURE &&
      this.currentStep !== SwapStep.VALIDATING_TRANSACTION
    )
  }

  mounted() {
    this.listenChanges()

    this.flamingoSwapService.setAccountToUse({
      address: this.$walletAdapter.address!,
      key: 'publicKey',
      type: 'publicKey',
    })
    this.flamingoSwapService.setSlippage(0.5)
    this.flamingoSwapService.setDeadline('10')
    this.flamingoSwapService.setTokenToReceive(null)
    this.flamingoSwapService.setTokenToUse(null)
    this.setAmountToReceive(null)
    this.setAmountToUse(null)
  }

  closeEvent() {
    this.flamingoSwapService.stopListeningBlockGeneration()

    this.flamingoSwapService.setAccountToUse({
      address: this.$walletAdapter.address!,
      key: 'publicKey',
      type: 'publicKey',
    })
    this.flamingoSwapService.setSlippage(0.5)
    this.flamingoSwapService.setDeadline('10')
    this.flamingoSwapService.setTokenToReceive(null)
    this.flamingoSwapService.setTokenToUse(null)
    this.flamingoSwapService.setAmountToReceive(null)
    this.flamingoSwapService.setAmountToUse(null)

    this.setPriceImpact(this.zeroStr)
    this.setIsReceiveInputValid(null)
    this.setIsUseInputValid(null)
    this.setIsUseMaxValid(null)
    this.setInvokeParams(null)

    this.setSlippage(0.5)

    this.reservesToReceive = this.zeroStr
    this.reservesToUse = this.zeroStr
    this.minimumReceived = this.zeroStr
    this.liquidityProviderFee = this.zeroStr
    this.maxAmountToUse = this.zeroStr
    this.deadlineInMinutes = 10
    this.route = []

    this.currentStep = SwapStep.FORM

    this.wrappingNeo = false
    this.unwrappingNeo = false

    this.isTokenToUseIndivisible = false
    this.isTokenToReceiveIndivisible = false
    this.isReceiveSelectValid = null
    this.transactionHash = null

    this.$emit('close')
  }

  async openEvent(token: TokensTableItem) {
    this.flamingoSwapService.startListeningBlockGeneration()

    await this.$await.run('populateAccountBalance', () =>
      this.populateAccountBalance()
    )

    await this.flamingoSwapService.setTokenToUse({
      hash: token.marketInformation?.hash!,
      symbol: token.symbol!,
      name: token.symbol!,
      decimals: token.marketInformation?.decimals || 0,
    })

    this.currentStep = SwapStep.FORM

    this.$emit('open')
  }

  async handleReviewProceedClick() {
    this.currentStep = SwapStep.WAITING_SIGNATURE

    const gasTokenBalance = this.accountBalance.find(
      balance => balance.token.symbol === 'GAS'
    )

    if (!gasTokenBalance) {
      this.currentStep = SwapStep.INSUFFICIENT_GAS
      return
    }

    try {
      if (!this.invokeParams) throw new Error()

      await this.updateInvokeParamsDeadLine()

      this.transactionHash = await this.$walletAdapter.invoke(this.invokeParams)

      if (!this.transactionHash) {
        this.currentStep = SwapStep.UNKNOWN_ERROR
        return
      }

      this.currentStep = SwapStep.VALIDATING_TRANSACTION

      const isTransactionValid = await NeoHelper.validateTransaction(
        this.transactionHash
      )

      if (!isTransactionValid) throw new Error()

      this.currentStep = SwapStep.SWAPPED
    } catch (error) {
      console.error(error)

      if (
        error.message &&
        String(error.message).includes('Exceeded the deadline')
      ) {
        this.currentStep = SwapStep.DEADLINE_EXCEEDED
        return
      }

      this.currentStep = SwapStep.UNKNOWN_ERROR
    }
  }

  handleUpdateDeadlineInMinutes(val: number) {
    this.flamingoSwapService.setDeadline(String(val))
  }

  async handleUpdateAllowedSlippageInPercentage(allowedSlippage: number) {
    this.flamingoSwapService.setSlippage(allowedSlippage)
  }

  async handleTokenToReceiveSelection(val: TokensTableItem) {
    this.isTokenToReceiveIndivisible = val.marketInformation?.decimals === 0

    if (this.isTokenToReceiveIndivisible) {
      this.setAmountToUse(null)
    }

    this.wrappingNeo =
      val.marketInformation?.hash === EnvHelper.VUE_APP_BNEO_SCRIPT_HASH &&
      this.tokenToUse?.marketInformation?.hash ===
        EnvHelper.VUE_APP_NEO_SCRIPT_HASH

    this.unwrappingNeo =
      val.marketInformation?.hash === EnvHelper.VUE_APP_NEO_SCRIPT_HASH &&
      this.tokenToUse?.marketInformation?.hash ===
        EnvHelper.VUE_APP_BNEO_SCRIPT_HASH

    await this.flamingoSwapService.setTokenToReceive({
      hash: val.marketInformation?.hash!,
      symbol: val.symbol!,
      name: val.symbol!,
      decimals: val.marketInformation?.decimals || 0,
    })

    this.isReceiveSelectValid = true
  }

  handleMaxClick() {
    this.setAmountToUse(this.maxAmountToUse)
  }

  async handleAmountToReceiveInput(val: string) {
    this.setAmountToReceive(val)
  }

  handleAmountToUseInput(val: string) {
    this.setAmountToUse(val)
  }

  async handleFormProceedClick() {
    this.setIsReceiveInputValid(this.amountToReceive)
    this.setIsUseInputValid(this.amountToUse)
    this.isReceiveSelectValid = !!this.tokenToReceive

    const swapArgs = this.flamingoSwapService.buildSwapInvocationArgs()

    this.setInvokeParams({
      method: 'swapInvocation',
      params: {
        ...swapArgs,
        address: this.$walletAdapter.address!,
      },
    })

    if (
      !this.isReceiveInputValid ||
      !this.isUseInputValid ||
      !this.isReceiveSelectValid ||
      this.priceImpactIsExtremellyHigh ||
      !this.isUseMaxValid
    ) {
      return
    }

    this.currentStep = SwapStep.REVIEW_FORM
  }

  @Watch('$store.state.walletAdapter.connectedWalletPlatform')
  async onAddressChange() {
    await this.populateAccountBalance()
  }

  setIsUseMaxValid(val: string | null) {
    this.isUseMaxValid =
      val !== null
        ? !this.maxAmountToUse || new BigNumber(val).lte(this.maxAmountToUse)
        : null
  }

  async updateInvokeParamsDeadLine() {
    if (this.invokeParams === null) throw new Error()

    let invokeParams:
      | SwapServiceSwapToReceiveArgs<BSNeo3NetworkId>
      | SwapServiceSwapToUseArgs<BSNeo3NetworkId>
      | null = null

    if (this.invokeParams.method === 'swapInvocation') {
      invokeParams = this.invokeParams.params as
        | SwapServiceSwapToReceiveArgs<BSNeo3NetworkId>
        | SwapServiceSwapToUseArgs<BSNeo3NetworkId>
    }

    if (!invokeParams) throw new Error()

    invokeParams.deadline = await this.calculateDeadlineInTimestamp(
      this.deadlineInMinutes
    )

    this.invokeParams.params = invokeParams
  }

  private async calculateDeadlineInTimestamp(
    minutesUntilExpiresTransaction: number
  ): Promise<string> {
    const utcDate = await Request.get('/client/global/utcTime')
      .as(Date)
      .getData()

    return new Date()
      .setMinutes(utcDate.getMinutes() + minutesUntilExpiresTransaction)
      .toString()
  }

  private async populateAccountBalance() {
    try {
      const address = this.$walletAdapter.address

      if (!address) throw new Error()

      this.accountBalance = await bsNeo3.blockchainDataService.getBalance(
        address
      )
    } catch (error) {
      this.threatError(
        this.$translate('components.MyWalletSwapModal.errors.unknownError')
      )
    }
  }

  private setMaxAmountToUse() {
    const tokenToUse = this.tokenToUse
    if (!tokenToUse) return

    const balanceTokenToUse = this.accountBalance.find(
      balance => balance.token.hash === tokenToUse.marketInformation?.hash!
    )!

    this.maxAmountToUse = balanceTokenToUse.amount
  }

  private setAmountToUse(val: string | null) {
    this.flamingoSwapService.setAmountToUse(val)
  }

  private setAmountToReceive(val: string | null) {
    this.flamingoSwapService.setAmountToReceive(val)
  }

  private listenChanges() {
    this.flamingoSwapService.eventEmitter.on(
      'amountToUse',
      this.handleChangeAmountToUse
    )
    this.flamingoSwapService.eventEmitter.on(
      'amountToReceive',
      this.handleChangeAmountToReceive
    )
    this.flamingoSwapService.eventEmitter.on(
      'tokenToUse',
      this.handleChangeTokenToUse
    )
    this.flamingoSwapService.eventEmitter.on(
      'tokenToReceive',
      this.handleChangeTokenToReceive
    )
    this.flamingoSwapService.eventEmitter.on(
      'minimumReceived',
      this.handleChangeMinimumReceived
    )
    this.flamingoSwapService.eventEmitter.on(
      'maximumSelling',
      this.handleChangeMaximumSelling
    )
    this.flamingoSwapService.eventEmitter.on(
      'deadline',
      this.handleChangeDeadline
    )
    this.flamingoSwapService.eventEmitter.on(
      'slippage',
      this.handleChangeSlippage
    )
    this.flamingoSwapService.eventEmitter.on(
      'liquidityProviderFee',
      this.handleChangeLiquidityProviderFee
    )
    this.flamingoSwapService.eventEmitter.on(
      'priceImpact',
      this.handleChangePriceImpact
    )
    this.flamingoSwapService.eventEmitter.on(
      'priceInverse',
      this.handleChangePriceInverse
    )
    this.flamingoSwapService.eventEmitter.on(
      'lastAmountChanged',
      this.handleChangeLastAmountChanged
    )
    this.flamingoSwapService.eventEmitter.on('route', this.handleChangeRoute)
  }

  private handleChangeRoute(val: SwapRoute[] | null) {
    if (!val) {
      this.route = []
      return
    }

    const route: TokensTableItem[] = []

    val.forEach(routeItem => {
      const tokenToUseConverted = this.tokensTableItemsCollection.items.find(
        item => item.marketInformation?.hash === routeItem.tokenToUse.hash
      )

      const tokenToReceiveConverted = this.tokensTableItemsCollection.items.find(
        item => item.marketInformation?.hash === routeItem.tokenToReceive.hash
      )

      route.push(tokenToUseConverted!)
      route.push(tokenToReceiveConverted!)
    })

    this.route = route.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          t =>
            t.marketInformation?.hash === item.marketInformation?.hash &&
            t.symbol === item.symbol
        )
    )
  }

  private handleChangeAmountToReceive(val: string | null) {
    this.amountToReceive = val ?? '0'
    this.setIsReceiveInputValid(val)

    const currentPrice = this.tokenToReceive?.marketInformation?.currentPrice

    this.amountToReceiveInDollar =
      val && currentPrice
        ? new BigNumber(val)
            .times(currentPrice)
            .toNumber()
            .toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 8,
            })
        : this.zeroStr
  }

  private handleChangeAmountToUse(val: string | null) {
    this.amountToUse = val ?? '0'
    this.setIsUseInputValid(val)
    this.setIsUseMaxValid(val)

    const currentPrice = this.tokenToUse?.marketInformation?.currentPrice

    this.amountToUseInDollar =
      val && currentPrice
        ? new BigNumber(val)
            .times(currentPrice)
            .toNumber()
            .toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 8,
            })
        : this.zeroStr
  }

  private handleChangeTokenToUse(val: Token | null) {
    if (!val) {
      this.tokenToUse = null
      return
    }

    const tokenTableItem = this.tokensTableItemsCollection.items.find(
      item => item.marketInformation?.hash === val.hash
    )

    if (!tokenTableItem) {
      this.threatError(
        this.$translate(
          'components.MyWalletSwapModal.errors.tokenPriceNotFound'
        )
      )
      return
    }

    this.tokenToUse = tokenTableItem
    this.isTokenToUseIndivisible =
      tokenTableItem.marketInformation?.decimals === 0
    this.setMaxAmountToUse()
  }

  private handleChangeTokenToReceive(val: Token | null) {
    if (!val) {
      this.tokenToReceive = null
      return
    }

    const tokenTableItem = this.tokensTableItemsCollection.items.find(
      item => item.marketInformation?.hash === val.hash
    )

    if (!tokenTableItem) {
      this.threatError(
        this.$translate(
          'components.MyWalletSwapModal.errors.tokenPriceNotFound'
        )
      )
      return
    }

    this.tokenToReceive = tokenTableItem
  }

  private handleChangeMinimumReceived(val: string | null) {
    this.minimumReceived = val ?? '0'
  }

  private handleChangeMaximumSelling(val: string | null) {
    this.maximumSelling = val ?? '0'
  }

  private handleChangeDeadline(val: string | null) {
    this.deadlineInMinutes = Number(val ?? '0')
  }

  private handleChangeSlippage(val: number) {
    this.setSlippage(val)
  }

  private handleChangeLiquidityProviderFee(val: string | null) {
    this.liquidityProviderFee = val ?? '0'
  }

  private handleChangePriceImpact(val: string | null) {
    this.setPriceImpact(val)
  }

  private handleChangePriceInverse(val: string | null) {
    this.priceInverse = val ?? '0'
  }

  private handleChangeLastAmountChanged(
    val: 'amountToReceive' | 'amountToUse' | null
  ) {
    this.lastAmountChanged = val
  }

  private setIsUseInputValid(val: string | null) {
    this.isUseInputValid =
      val !== null ? new BigNumber(val).gt(this.zeroBn) : null
  }

  private setIsReceiveInputValid(val: string | null) {
    this.isReceiveInputValid =
      val !== null ? new BigNumber(val).gt(this.zeroBn) : null
  }

  private setPriceImpact(val: string | null) {
    this.priceImpact = val || '0'
    this.priceImpactIsExtremellyHigh =
      val !== null ? new BigNumber(val).gt(2) : null
    this.priceImpactIsHigh =
      val !== null
        ? new BigNumber(val).gt(1) && new BigNumber(val).lte(2)
        : null
  }

  private setSlippage(val: number | null) {
    this.allowedSlippageInPercentage = val || 0
    this.slippageIsHigh = val !== null ? new BigNumber(val).gte(5.1) : null
  }

  private setInvokeParams(val: InvokeParams | null) {
    this.invokeParams = val
  }

  private threatError(message: string) {
    this.$toast.abort(message)
    this.$modal.close('MyWalletSwapModal')
  }
}
