
































































import {Component, Watch, Mixins, Prop} from 'vue-property-decorator'
import GasRewardsCard from '@/components/gasCalculator/GasRewardsCard.vue'
import GasCalculatorCard from '@/components/gasCalculator/GasCalculatorCard.vue'
import {GasCalculatorTargetCollection} from '@/model/collection/GasCalculatorTargetCollection'
import {PriceHistoryCollection} from '@/model/collection/PriceHistoryCollection'
import {GasCalculatorTarget} from '@/model/resource/GasCalculatorTarget'
import {NeoHelper} from '@/helpers/NeoHelper'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import CandidateVoteModal from '@/components/candidateVote/CandidateVoteModal.vue'

@Component({
  components: {CandidateVoteModal, GasCalculatorCard, GasRewardsCard},
})
export default class MyWalletGasCalculatorSection extends Mixins(
  MixinScreenSize
) {
  @Prop({type: Number, required: true}) neoBalance!: number

  gasCalculatorTargetCollection = new GasCalculatorTargetCollection()
  priceHistoryCollection = new PriceHistoryCollection()

  currentCandidateVoted: GasCalculatorTarget | null = null
  selectedCandidate: GasCalculatorTarget | null = null

  neoOwned: string = ''
  lastTimeUpdated: string = ''

  currentCandidateVotedDailyAmount: number = 0
  gasPriceInDollar: number = 0
  dailyAmount: number = 0
  weeklyAmount: number = 0
  monthlyAmount: number = 0
  yearlyAmount: number = 0
  apr: number = 0

  useNeoBurger: boolean = false
  isSameCandidate: boolean = false
  isNoCandidate: boolean = false
  hasInsufficientNeo: boolean = false
  useNeoCompounder: boolean = false

  openModal() {
    if (
      !this.selectedCandidate ||
      !this.currentCandidateVoted ||
      this.selectedCandidate.scriptHash ===
        this.currentCandidateVoted.scriptHash
    ) {
      return
    }

    this.$modal.open('candidateVoteModal', {
      gasCurrentPriceInDollar: this.gasPriceInDollar,
      selectedCandidateDailyAmount: this.dailyAmount,
      currentCandidateVotedDailyAmount: this.currentCandidateVotedDailyAmount,
      selectedCandidate: this.selectedCandidate,
      currentCandidateVoted: this.currentCandidateVoted,
    })
  }

  async created() {
    window.scrollTo(0, 0)
    this.applyCollection()
    await this.populateResources()
  }

  applyCollection() {
    this.gasCalculatorTargetCollection.withoutTotal = true
    this.gasCalculatorTargetCollection.noPagination()

    this.priceHistoryCollection.withoutTotal = true
    this.priceHistoryCollection.noPagination()
    this.priceHistoryCollection.symbols = ['GAS']
  }

  async populateResources() {
    const promises: Array<Promise<unknown>> = [
      this.gasCalculatorTargetCollection.queryAsPage(),
      this.priceHistoryCollection.queryAsPage(),
    ]

    await this.$await.run('populateCandidates', async () => {
      await Promise.all(promises)

      await this.$utils.sleep(5000)

      await this.populateInitialValues()

      this.lastTimeUpdated = this.gasCalculatorTargetCollection.lastDailyGasRateTimeUpdated

      const gasHistory = this.priceHistoryCollection.items.find(
        item => item.contractNep17?.symbol === 'GAS'
      )

      if (!gasHistory) return

      this.gasPriceInDollar = Number(gasHistory.currentPrice)
    })
  }

  async populateInitialValuesWithSession() {
    const {candidatePublicKey} = await NeoHelper.getVoterData()

    const noCandidateWhenResetted = '00'
    const noCandidateWhenNeverVoted = ''

    const isNoCandidate =
      candidatePublicKey === noCandidateWhenNeverVoted ||
      candidatePublicKey === noCandidateWhenResetted

    if (isNoCandidate) {
      this.currentCandidateVoted = this.gasCalculatorTargetCollection.sortedCandidates[0]
    } else {
      this.currentCandidateVoted =
        this.gasCalculatorTargetCollection.sortedCandidates.find(
          candidate => candidatePublicKey === `0${candidate.publicKey}`
        ) ?? null
    }

    if (this.currentCandidateVoted) {
      this.setSelectedCandidate(this.currentCandidateVoted)
    }

    this.setNeoOwned(String(this.neoBalance))

    this.hasInsufficientNeo = Number(this.neoBalance) === 0
  }

  async populateInitialValues() {
    if (this.$walletAdapter.connectedWalletPlatform) {
      await this.populateInitialValuesWithSession()
      return
    }
  }

  setNeoOwned(val: string) {
    this.neoOwned = val
    this.calculateValues()
  }

  setUseNeoBurger(value: boolean) {
    this.useNeoBurger = value
    this.calculateValues()
  }

  setUseNeoCompounder(value: boolean) {
    this.useNeoCompounder = value
    this.calculateValues()
  }

  handleSwapped() {
    this.currentCandidateVoted = this.selectedCandidate
    this.isSameCandidate = true
  }

  setSelectedCandidate(node: GasCalculatorTarget) {
    this.selectedCandidate = node
    this.calculateValues()

    this.isSameCandidate =
      node.scriptHash === this.currentCandidateVoted?.scriptHash

    this.isNoCandidate = !this.currentCandidateVoted || !node.publicKey
  }

  @Watch('dailyAmount', {immediate: true})
  onDailyAmountChange() {
    this.weeklyAmount = this.$utils.weeklyAmount(this.dailyAmount)
    this.monthlyAmount = this.$utils.monthlyAmount(this.dailyAmount)
    this.yearlyAmount = this.$utils.yearlyAmount(this.dailyAmount)
  }

  @Watch('$store.state.walletAdapter.connectedWalletPlatform')
  handleSessionChange() {
    this.$await.run('populate', async () => {
      await this.populateInitialValues()
    })
  }

  private calculateValues() {
    let nodeCandidate

    if (this.useNeoBurger) {
      nodeCandidate = this.gasCalculatorTargetCollection.neoBurger
    } else {
      nodeCandidate = this.selectedCandidate
    }

    if (!nodeCandidate || !nodeCandidate?.dailyGasRate) return

    this.dailyAmount =
      Number(nodeCandidate.dailyGasRate) * Number(this.neoOwned)

    this.apr = Number(nodeCandidate.apr)

    if (!this.currentCandidateVoted || !this.currentCandidateVoted.dailyGasRate)
      return

    this.currentCandidateVotedDailyAmount =
      Number(this.currentCandidateVoted.dailyGasRate) * Number(this.neoOwned)
  }
}
