











































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import GasRewardsCard from '@/components/gasCalculator/GasRewardsCard.vue'
import GasCalculatorCard from '@/components/gasCalculator/GasCalculatorCard.vue'
import PoweredBy, {PoweredByItem} from '@/components/PoweredBy.vue'
import {GleederHelper} from '@/helpers/GleederHelper'
import {EnvHelper} from '@/helpers/EnvHelper'
import BigNumber from 'bignumber.js'
import MyWalletGetGasTokenToUse from '@/components/myWallet/MyWalletGetGasTokenToUse.vue'
import MyWalletGetGasTokenToReceive from '@/components/myWallet/MyWalletGetGasTokenToReceive.vue'

@Component({
  components: {
    MyWalletGetGasTokenToReceive,
    MyWalletGetGasTokenToUse,
    PoweredBy,
    GasCalculatorCard,
    GasRewardsCard,
  },
})
export default class MyWalletGetGasSection extends Vue {
  @Prop({type: Number, required: true}) neoBalance!: number

  poweredByList = [
    {
      url: 'https://https://gleeder.app//',
      alt: 'Gleeder icon',
      filename: 'gleeder-icon.svg',
    } as PoweredByItem,
  ]

  amountToReceive: string = this.toFixedOrEmpty(null)
  amountToUse: string = this.toFixedOrZero(null)
  neoOwned: string = this.toFixedOrZero(null)
  transactionHash: string | null = null
  gasOut: number = 0

  get isSwapDisabled() {
    return (
      !this.amountToUse.trim() ||
      this.amountToUse.trim() === '0' ||
      new BigNumber(this.amountToUse).gt(new BigNumber(this.neoOwned!))
    )
  }

  async mounted() {
    await this.populateGasOut()
  }

  @Watch('neoBalance')
  async onNeoBalanceChange() {
    this.neoOwned = String(this.neoBalance)
  }

  async populateGasOut() {
    const response = await GleederHelper.getAmountsOutFlamingo({
      amountIn: '1',
      decimalsTokenToUse: EnvHelper.VUE_APP_BNEO_DECIMALS,
      hashTokenToUse: EnvHelper.VUE_APP_BNEO_SCRIPT_HASH,
      hashTokenToReceive: GleederHelper.gasScriptHash,
    })

    this.gasOut = Number(
      (response.stack[0].value as {type: string; value: string}[])[1].value
    )
  }

  async handleSwapClick() {
    await this.$walletAdapter.executeWithConnectedWallet(() => {
      this.$modal.open('getGasModal', {
        amountToReceive: this.amountToReceive,
        amountToUse: this.amountToUse,
      })
    })
  }

  handleAmountToUseInput(val: string) {
    this.amountToUse = val

    if (!this.amountToUse || Number(this.amountToUse) === 0) {
      this.setAmountToReceive(null)
      return
    }

    this.amountToReceive = BigNumber(this.gasOut)
      .multipliedBy(this.amountToUse)
      .shiftedBy(-EnvHelper.VUE_APP_BNEO_DECIMALS) // Decimals fixed back
      .minus(0.5) // Business rule: we'll display for the user is always 0.5 less the value we received from RPC
      .toString()
  }

  private setAmountToReceive(
    val: string | null,
    shouldSetAmountToUse: boolean = true
  ) {
    this.amountToReceive = this.toFixedOrEmpty(val)

    if (!shouldSetAmountToUse) return

    if (!val) {
      this.setAmountToUse(null, false)
      return
    }
  }

  private toFixedOrEmpty(val: string | null) {
    if (!val) return ''

    return val.length === 0 ? '' : new BigNumber(val).toFixed()
  }

  private toFixedOrZero(val: string | null) {
    if (!val) return '0'

    return val.length === 0 ? '0' : new BigNumber(val).toFixed()
  }

  private setAmountToUse(
    val: string | null,
    shouldSetAmountToReceive: boolean = true
  ) {
    this.amountToUse = this.toFixedOrZero(val)

    if (!shouldSetAmountToReceive) return

    if (!val) {
      this.setAmountToReceive(null, false)
      return
    }
  }
}
