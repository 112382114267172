import { render, staticRenderFns } from "./MyWalletSwapTokenToUseInput.vue?vue&type=template&id=0becd449&scoped=true"
import script from "./MyWalletSwapTokenToUseInput.vue?vue&type=script&lang=ts"
export * from "./MyWalletSwapTokenToUseInput.vue?vue&type=script&lang=ts"
import style0 from "./MyWalletSwapTokenToUseInput.vue?vue&type=style&index=0&id=0becd449&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0becd449",
  null
  
)

export default component.exports