

































































































import {Vue, Component, Prop} from 'vue-property-decorator'
import MyWalletTotalBalanceCard from '@/components/myWallet/MyWalletTotalBalanceCard.vue'
import MyWalletAddressCard from '@/components/myWallet/MyWalletAddressCard.vue'
import MyWalletUnclaimedGasSection from '@/components/myWallet/MyWalletUnclaimedGasSection.vue'

@Component({
  components: {
    MyWalletUnclaimedGasSection,
    MyWalletTotalBalanceCard,
    MyWalletAddressCard,
  },
})
export default class MyWalletSection extends Vue {
  @Prop({type: Number, required: true})
  totalBalanceAmountInDollar!: number
  @Prop({type: Number, required: true}) totalBalancePercentageVariation!: number
  @Prop({type: Boolean, default: false}) isRefreshing!: boolean
  @Prop({type: String, required: true}) unclaimedGas!: string
  @Prop({type: String, required: true}) fee!: string

  refreshData() {
    this.$emit('refreshData')
  }
}
