



































































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import MyWalletClaimGasModal from '@/components/myWallet/MyWalletClaimGasModal.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {MyWalletClaimGasModal},
})
export default class MyWalletUnclaimedGasSection extends MixinScreenSize {
  @Prop({type: String, required: true}) unclaimedGas!: string
  @Prop({type: String, required: true}) fee!: string

  isProceedDisabled = Number(this.fee) >= Number(this.unclaimedGas)

  @Watch('unclaimedGas')
  onUnclaimedGasChange() {
    this.isProceedDisabled = Number(this.fee) >= Number(this.unclaimedGas)
  }

  openClaimGasModal() {
    this.$modal.open('myWalletClaimGasModal')
  }
}
