















































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import VueMultiselect from 'vue-multiselect/src/Multiselect.vue'
import {PropType} from 'vue'
import SwapInput from '@/components/swapToken/SwapInput.vue'
import SwapTokenAccountBalance from '@/components/swapToken/SwapTokenAccountBalance.vue'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import TooltipMobileContent from '@/components/tooltip/TooltipMobileContent.vue'
import Tooltip from '@/components/tooltip/Tooltip.vue'
import {EnvHelper} from '@/helpers/EnvHelper'
import {BalanceResponse} from '@/libs/blockchain-services/types'

@Component({
  computed: {
    EnvHelper() {
      return EnvHelper
    },
  },
  components: {
    SwapTokenAccountBalance,
    SwapInput,
    VueMultiselect,
    TooltipMobileContent,
    Tooltip,
  },
})
export default class MyWalletSwapTokenToReceiveInputAndSelect extends Vue {
  @Prop({type: String, required: true}) amount!: string
  @Prop({type: String, required: true}) amountInDollar!: string
  @Prop({type: Array, required: true}) accountBalance!: BalanceResponse[]
  @Prop({type: Array, required: true}) options!: TokensTableItem[]
  @Prop({type: Object as PropType<TokensTableItem | null>})
  token!: TokensTableItem | null
  @Prop({type: Boolean, required: false, default: true})
  showDollarAmount!: boolean

  tokenAccountBalance: number = 0

  @Prop({
    type: ((null as unknown) as Object) as PropType<Boolean | null>,
    required: true,
  })
  selectIsValid!: boolean | null
  @Prop({
    type: ((null as unknown) as Object) as PropType<Boolean | null>,
    required: true,
  })
  inputIsValid!: boolean | null

  @Prop({type: Boolean, required: true}) isTokenToReceiveIndivisible!: boolean
  @Prop({type: Boolean, required: true}) isTokenToUseIndivisible!: boolean
  isMaxAmount = false
  maxAmount: string | null = null
  mobileTooltipIsVisible = false

  setMobileTooltip(val: boolean) {
    this.mobileTooltipIsVisible = val
  }

  handleSelect(val: TokensTableItem) {
    this.$emit('select', val)
  }

  handleInput(val: string) {
    this.$emit('input', val)
    this.isMaxAmount = this.maxAmount === val && val !== ''
  }

  focusOnInput() {
    const input = this.$el.querySelector(
      '.swap-input-select__input'
    ) as HTMLInputElement
    input.focus()
  }

  @Watch('amount')
  async handleMaxAmount() {
    if (this.maxAmount) {
      this.isMaxAmount = this.maxAmount === this.amount && this.amount !== ''
    }
  }

  @Watch('token')
  setTokenAccountBalance() {
    const balance = this.accountBalance.find(
      balance => balance.token.hash === this.token?.marketInformation?.hash
    )

    this.tokenAccountBalance = Number(balance?.amount ?? 0)
  }
}
